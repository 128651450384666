"use strict";
import {findIn} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import axios from "axios";
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import * as scrollingAreaCustom from "@elements/scroll-slider";

export function init() {

    onFind('.js-async-slider', function (element) {

        onEnterViewPort(element, () => {
            let area = findIn('.js-async-slider__area', element);
            let pendingRequest = axios({method: 'POST', url: getPrefixedDataSet('async-slider', element).url, headers: {'X-Requested-With': 'XMLHttpRequest'}, });

            pendingRequest.then((x) => {
                if(x.data.success === true){
                    const result = x.data.html;

                    let markupElement = document.createElement('div'),
                        markup = result;
                    markupElement.innerHTML = markup;
                    let newSlides = markupElement.childNodes;
                    newSlides.forEach((item) => {
                        area.appendChild(item);
                    });

                    initInScope(area);

                    scrollingAreaCustom.init({
                        arrows: true,
                        dots: true,
                        arrowIconClass: 'icon icon-arrow-long',
                        arrowPrevClass: 'scroll-slider__arrow--prev gtm-slider-header__arrow',
                        arrowNextClass: 'scroll-slider__arrow--next gtm-slider-header__arrow'
                    },{
                        base: '.js-async-slider',
                        area: '.js-async-slider__area',
                        item: '.js-async-slider__item',
                        dots: '.js-async-slider__dots'
                    });
                }
            });
        });
    });
}