import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";

export function init() {
    if(window.matchMedia("(min-width: 768px)").matches){
        onFind('.js-gradient-animation', (element) => {
            on('mousemove', e => {
                let width = window.innerWidth;
                let height = window.innerHeight;

                let xPercent = Math.round(e.pageX / width * 100);
                let yPercent = Math.round(e.pageY / height * 100);

                let percent = xPercent + yPercent;

                element.style.setProperty('--gradient-primary-vertical', 'linear-gradient(' + percent + 'deg, #FFED00 0%, rgba(255,237,0,0.33) 100%)');
            }, document.body)
        });
    }
}