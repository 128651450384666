import {onFind} from "@elements/init-modules-in-scope";
import {add} from "@elements/scroll-animations";

export function init() {
    onFind('.js-hero-animation', (baseElement) => {
        let heroElements = [];
        heroElements.push(baseElement);

        add(
            heroElements,
            calculateAnimationProgress,
            setAnimationProgress
        )
    });
}

// gets element -> radius for clippath animation and end is for stopping the animation after 1200px to reduce cpu load
function calculateAnimationProgress(element) {
    let start = window.matchMedia("(min-width: 768px)").matches ? 38.8 : 57;
    let divider =  window.matchMedia("(min-width: 768px)").matches ? 70 : 20;

    if(window.matchMedia("(min-width: 2000px)").matches){
        start = 25;
    }

    return {
        radius: start - (element.getBoundingClientRect().top * -1)/divider,
        end: (element.getBoundingClientRect().top * -1) > 1200 ? 0 : 1
    }
}

// gets element & progress
function setAnimationProgress(element, progress) {
    if(progress.end !== 0){
        element.style.clipPath = 'circle(' + progress.radius + 'vw at 38% 29.5% )';
    }
}
