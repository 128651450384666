import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:

import * as gradientAnimation from './scripts/gradient-animation';
gradientAnimation.init();

import * as heroAnimation from './scripts/hero-animation';
heroAnimation.init();

import * as asyncSlider from './scripts/async-slider';
asyncSlider.init();

